import React from 'react';
import { FormLabel } from '@uikit';
import { InputProps } from '../types';
import { useMutation, UploadDocument, DocumentUploads, DocumentRequestType } from '@app/data';
import { DocumentUploadType } from '@app/types';
import FileDropper from '@app/_common/components/FileDropper';
import { SupportedFileType } from '@hooks/useUpload';

export interface FileInputProps<T> extends InputProps<T> {
  documentType?: DocumentUploadType | DocumentRequestType;
  filePrefix?: string;
  fileTypes?: SupportedFileType[];
  inputKey: 'id' | 'filePath';
  skipUpload?: boolean;
}

const FileInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      inputKey = 'id',
      filePrefix,
      documentType,
      label,
      sublabel,
      help,
      optional,
      required,
      onChange,
      skipUpload,
      fileTypes,
    }: FileInputProps<T>,
    ref,
  ) => {
    const [uploadDoc] = useMutation(UploadDocument, {
      onCompleted: (data) => onChange(data?.uploadDocument?.[inputKey]),
      refetchQueries: [DocumentUploads],
    });

    if (!documentType) return null;

    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
          required={required}
        />

        <FileDropper
          filePrefix={filePrefix || 'upload'}
          fileTypes={fileTypes}
          handleDelete={() => onChange(null)}
          handleDrop={async ({ fileKey, fileType }) => {
            onChange(undefined); // reset first...

            if (skipUpload) {
              onChange({
                key: fileKey,
                filetype: fileType,
              });
            } else {
              uploadDoc({
                variables: {
                  input: {
                    key: fileKey,
                    fileType,
                    documentType,
                  },
                },
              });
            }
          }}
        />
      </>
    );
  },
);

FileInput.displayName = 'FileInput';
export default FileInput;
