import { useEffect } from 'react';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { ALREADY_AETNA } from '@app/constants/aetna';

/**
 * This is the full set of params that we support checking for
 * hid: the id of a public explorer session
 * r: a referral code
 */
export interface PassedParams {
  hid?: string;
  r?: string;
  partner_param?: string;
  white_label?: string;
  ask?: string;
  phase?: string;

  // not sent in the URL params, but should be passed through
  returnTo?: string;
}

export interface ParameterStore {
  shouldHandleRedirect: boolean;
  shouldAttestAetna: boolean;
  isOnboarding: boolean;
  markAsOnboarding: () => void;
  returnTo?: string;
  params: PassedParams;
  setParams: (params: PassedParams, shouldHandleRedirect?: boolean, alreadyAetna?: boolean) => void;
  clearParams: () => void;
}

/**
 * The parameter store keeps track of URL params across sessions
 * - We use persistent storage so that it is stored in local storage, if necessary
 */
export const useParameterStore = create<ParameterStore>()(
  devtools(
    persist(
      (set, get) => ({
        shouldHandleRedirect: false,
        shouldAttestAetna: false,
        isOnboarding: false,
        markAsOnboarding: () => set({ isOnboarding: true }),

        params: {},

        // merges the params with any existing
        setParams: (
          params: PassedParams,
          shouldHandleRedirect?: boolean,
          shouldAttestAetna?: boolean,
        ) => {
          // filter out any params that are undefined
          const cleanedParams = Object.entries(params)
            .filter(([key, value]) => value !== undefined)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

          // merge with the existing params
          set({
            params: { ...get().params, ...cleanedParams },
            shouldHandleRedirect,
            shouldAttestAetna,
          });
        },
        clearParams: () => {
          set({ params: {}, shouldHandleRedirect: false, isOnboarding: false });
        },
      }),
      {
        name: 'catch-signup-params',
      },
    ),
  ),
);

/**
 * Handles the interaction with signup params. Here's how it works:
 *
 * Whenever we hit the site for the first time, we...
 * 1) stash the params in the browser via useParameterStore
 * 2) if unauthenticated, we pass the params via app state in Auth0
 * 3) when returning from Auth0, we merge the app state back into stored params
 * 4) lastly, when succesful authentication is performed, we send signup params
 * in the findOrCreateUser call, invoke any special functionality, then clear the params
 */
export const useSignupParams = (options: { isRoot?: boolean } = {}) => {
  const {
    isOnboarding,
    shouldHandleRedirect,
    shouldAttestAetna,
    params,
    setParams,
    clearParams,
    markAsOnboarding,
  } = useParameterStore();

  useEffect(() => {
    if (options.isRoot) {
      const urlParams = new URLSearchParams(window.location.search);

      const hid = urlParams.get('hid') || undefined;
      const r = urlParams.get('r') || undefined;
      const partner_param = urlParams.get('partner_param') || undefined;
      const white_label = urlParams.get('white_label') || undefined;
      const ask = urlParams.get('ask') || undefined;
      const phase = urlParams.get('phase') || undefined;

      // whenever hid is set, we should always go to /welcome screen
      // this ensures that we handle the import even if the user is already logged in
      const promptForRedirect = !!hid;

      //if ask is set to already_aetna, we show the user the Aetna attestation screen first
      //if the user confirms, we route them to the financial product
      const shouldAttestAetna = ask === ALREADY_AETNA;

      if (urlParams.toString()) {
        setParams(
          { hid, r, partner_param, white_label, ask, phase },
          promptForRedirect,
          shouldAttestAetna,
        );
      }
    }
  }, []);

  return {
    isOnboarding,
    shouldHandleRedirect,
    shouldAttestAetna,
    params,
    setParams,
    clearParams,
    markAsOnboarding,
  };
};
