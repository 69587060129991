import React, { useMemo, useState } from 'react';
import { View } from 'react-native';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { BottomActions } from '@common/layouts/BottomActions';
import { PromptCardParams } from './types';
import { Stack } from '@layouts/Stack';
import { Layout } from '@layouts/Layout';
import { Asset, ContextMenu, Pressable, Gradient, ComplexRow } from '@uikit';
import { useTheme } from '@uikit/hooks/useTheme';
import { Actions } from '@app/config';
import { CatchTheme } from '@app/_ui-kit/themes/catch';
import { useNudge } from '@app/hooks/useNudge';
import { handleAction } from '@app/navigate';

import More from '@svg/dots-horizontal.svg';

/**
 * PromptCard
 */
const PromptCard = ({
  loading,
  disabled,
  name,
  title,
  subtitle,
  dismissable,
  article,
  guide,
  icon,
  actions = [],
  wide,
  wideMobile,
  asset,
  limitLines,
  resumeHealth,
  enrollId,
  gradient,
  render,
  defaultAction,
  overrideAction,
  size = 'CARD',
  children,
  mobileSnap,
  momentary,
}: PromptCardParams) => {
  const [setLoading] = useState<boolean>(false);
  const { theme } = useTheme();
  const { isMobile, width } = useResponsive();
  const { dismissNudge } = useNudge(name);

  const CARD_WIDTH =
    wide && !isMobile
      ? '100%'
      : (wide || wideMobile) && isMobile
      ? width - CatchTheme.spacing.mobile.margins * (mobileSnap ? 0 : 2)
      : size === 'CARD'
      ? CatchTheme.constants.promptWidthLg
      : '100%';

  const assetSize = size === 'CARD' ? 'xxl' : 'sm';
  const _asset =
    asset ||
    (!!render
      ? {
          size: assetSize,
          render,
        }
      : !!icon
      ? {
          size: assetSize,
          containerSize: assetSize,
          svg: icon,
        }
      : undefined);

  const onPromptPress = useMemo(() => {
    if (size === 'ALERT') {
      if (overrideAction) return overrideAction;
      const firstAction = actions?.[actions.length - 1];

      if (firstAction?.type || firstAction?.onPress || firstAction?.onAction) {
        return () => handleAction(firstAction, {}, setLoading);
      } else {
        return overrideAction || defaultAction;
      }
    } else {
      return () => {};
    }
  }, [size, overrideAction, defaultAction, actions]);

  const Body =
    size === 'CARD' ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Stack>
          <>
            <View
              style={[
                theme.topSpace2,
                theme.xSpace1,
                actions && actions?.length > 0 && actions?.[0]
                  ? theme.bottomSpace1
                  : theme.bottomSpace3,
              ]}
            >
              <Layout.Header
                light
                titleLines={limitLines ? 1 : undefined}
                titleColor={'text'}
                subtitleColor={'secondary'}
                title={title}
                subtitle={subtitle}
                titleSize="form"
                subtitleSize="fp"
                sheet
                spaced
                align="center"
                loading={loading}
                asset={_asset}
              />
            </View>
          </>
          {children}
        </Stack>
        <BottomActions
          routeProps={{ nudgeId: name, resumeHealth, enrollId }}
          actions={actions?.map((action, idx) => ({
            ...action,
            onAction: overrideAction || action?.onAction || defaultAction,
          }))}
        />
      </div>
    ) : (
      // I think the various types of cards should prob be pulled outside of here
      <View style={[theme.xSpace3, theme.ySpace0b, theme.rightSpace2, theme.fullWidth]}>
        <ComplexRow
          asset={{ size: assetSize, ..._asset }}
          label={title}
          sublabel={subtitle}
          accessory={
            loading
              ? 'loading'
              : /ROUTE|CUSTOM|LINK/.test(actions?.[actions.length - 1]?.type) && 'arrow'
          }
        />
      </View>
    );

  return (
    <Layout mobileSnap={size === 'ALERT' && mobileSnap && isMobile} style={{ height: '100%' }}>
      <Pressable
        disabled={disabled}
        hoveredStyle={size === 'ALERT' && [theme._hover]}
        handleOnPress={() => {
          if (momentary) dismissNudge();
          if (onPromptPress) onPromptPress();
        }}
        style={[
          // size === 'CARD' && theme.cardShadow,
          size === 'CARD' ? theme.cardBg : theme.skeletonBg,
          (size === 'CARD' || !isMobile) && theme.largeCorners,
          theme.rightGutter0b,
          theme.fadeInUp,
          {
            overflow: 'visible',
            width: CARD_WIDTH,
            maxWidth: CARD_WIDTH,
            height: '100%',
          },
        ]}
      >
        <Gradient
          color={size === 'CARD' || gradient}
          color2={size === 'CARD' || gradient}
          gradient={size === 'CARD' && gradient}
          style={[
            (size === 'CARD' || !isMobile) && theme.largeCorners,
            size === 'CARD' && theme.thinBorder,
            { overflow: 'hidden', height: '100%' },
          ]}
        >
          <View style={size === 'CARD' ? styles.contextMenu : styles.contextMenuSm}>
            <ContextMenu
              options={[
                {
                  ...Actions.DISMISS,
                  hidden: !dismissable,
                  onAction: () => dismissNudge(),
                },
                {
                  ...Actions.ARTICLE,
                  hidden: !article,
                  onAction: () => {
                    window.open(article || '');
                  },
                },
                {
                  ...Actions.GUIDE,
                  hidden: !guide,
                  onAction: () => {
                    window.open('https://catch.co/guides/' + guide || '');
                  },
                },
                Actions.HELP,
              ]}
            >
              <Asset
                containerSize="xs"
                size="xs"
                svg={More}
                color="subtle"
                bg="skeleton"
                bgHover="border"
                shape="circle"
              />
            </ContextMenu>
          </View>
          {Body}
        </Gradient>
      </Pressable>
    </Layout>
  );
};

const styles = {
  contextMenu: {
    position: 'absolute',
    zIndex: 99,
    right: 16,
    top: 16,
  },
  contextMenuSm: {
    display: 'none',
    position: 'absolute',
    zIndex: 99,
    right: 12,
    top: 12,
  },
};

export default PromptCard;
