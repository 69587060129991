import { AccountDocument, GroupedDocuments } from './types';

/**
 * Helper function to pull month of document
 * The two APIs reference the month differently, so this standardizes it
 */
const getDocumentMonth = (doc: AccountDocument): string => {
  if ('period' in doc && doc.period) {
    return doc.period;
  }

  if ('month' in doc && doc.month) {
    return doc.month;
  }

  return '';
};

/**
 * Given an array of documents, groups them by month
 */
export const groupDocuments = (docs: Array<AccountDocument>): Array<GroupedDocuments> => {
  const grouped = docs.reduce<Record<string, AccountDocument[]>>((acc, doc) => {
    const month = getDocumentMonth(doc);
    if (!acc[month]) acc[month] = [];
    acc[month].push(doc);
    return acc;
  }, {});

  return Object.entries(grouped)
    .map(([month, documents]) => ({ month, documents }))
    .sort((a, b) => b.month.localeCompare(a.month));
};
