// import { chooseHealthApp } from '@app/utils';
import { PromptName } from '@types';
import type { Situation } from '@types';
import { Prompts } from '@app/config/prompts';
import { chooseHealthApp } from './chooseHealthApp';
import {
  BankLinkStatus,
  GetFintechHomeQuery,
  HealthPathwayType,
  HomeQuery,
  IncomeStatus,
} from '@app/data';

interface Prompt {
  name: PromptName;
  data?: {};
}

const applyPrompt = (promptName, data) => {
  const config = Prompts[promptName];

  if (!config) console.error('MISSING PROMPT', promptName);
  return typeof config === 'function' ? config(data) : config;
};

const checkDependencies = (promptName, situations): boolean => {
  const prompt = applyPrompt(promptName, {});
  return prompt ? prompt.dependencies.every((dep) => !!situations[dep]) : false;
};

const getCurrentApplications = (data, year) => {
  return (data?.viewerTwo?.health?.applications || data?.viewer?.health?.applications || []).filter(
    (app) => app.coverageYearNumber === year,
  );
};

const getCurrentPolicies = (data, year) => {
  return (data?.viewerTwo?.health?.policies || []).filter((policy) => policy.coverageYear === year);
};

/**
 * Given query data and a list of prompts to show,
 * we return computed set of prompts
 */
export const getPrompts = (
  data: HomeQuery | undefined,
  fintechData: GetFintechHomeQuery | undefined,
  { showPrompts = Object.keys(PromptName), isDebug },
): Array<Prompt> => {
  const displayPrompts: Array<Prompt> = [];
  const oeDates = data?.reference?.health?.openEnrollmentDates;

  const coverageYear =
    oeDates?.isOpenEnrollment || oeDates?.isWindowShopping
      ? oeDates?.oeCoverageYear
      : oeDates?.sepCoverageYear;

  const currentApps = getCurrentApplications(data, coverageYear);
  const currentPolicies = getCurrentPolicies(data, coverageYear);
  const incomes = fintechData?.pagedIncome?.edges || [];
  const bankLinks = fintechData?.bankLinks || [];
  const idpStatus = data?.viewerTwo?.cmsIdentity?.status;
  const applications = data?.viewerTwo?.health?.applications || [];
  const documents = data?.viewerTwo?.documents?.taxDocuments || [];
  const pathway = data?.viewerTwo?.healthExplorerData?.pathway;
  const dismissedNudges = data?.viewer?.nudges?.filter((n) => n?.isDismissed)?.map((n) => n.id);

  // determines situations based on passed data
  const situations: Record<Situation, boolean> = {
    /** HEALTH */
    FFM_STATE: pathway === HealthPathwayType.Ffm,
    STATE_APP: pathway === HealthPathwayType.StateApp,
    SBM_STATE: pathway === HealthPathwayType.Sbm,
    ASSUMED_CAN_APPLY: !pathway || pathway !== HealthPathwayType.Sbm,

    HEALTH: applications.length > 0,

    /** OPEN ENROLLMENT */
    OPEN_ENROLLMENT: !!oeDates?.isOpenEnrollment,
    NOT_OPEN_ENROLLMENT: !oeDates?.isOpenEnrollment,
    WINDOW_SHOPPING: !!oeDates?.isWindowShopping,
    NOT_WINDOW_SHOPPING: !oeDates?.isWindowShopping,

    NO_CURRENT_APPS: currentApps?.length === 0,
    NO_CURRENT_POLICIES: currentPolicies?.length === 0,
    HAS_ENROLLED:
      currentPolicies?.length > 0 || currentApps?.some((app) => app.status === 'ENROLLED'),
    HAS_NOT_ENROLLED:
      currentPolicies?.length === 0 && currentApps?.every((app) => app.status !== 'ENROLLED'),
    HAS_NOT_SUBMITTED:
      currentPolicies?.length === 0 &&
      currentApps?.every((app) => app.status !== 'SUBMITTED' && app.status !== 'ENROLLED'),

    /** INCOME */
    INCOME: incomes?.length > 0,
    NO_INCOME: incomes?.length === 0,

    /** currently unused/todo */
    MANUAL_ONLY: false,
    NO_HEALTH: false,
    NEED_HEALTH_DOCS: false,
    HEALTH_IN_PROGRESS: false,
    MANUAL_ID_REVIEW: idpStatus === 'PENDING_MANUAL_REVIEW',
    DOCS_AVAILABLE: documents.length > 0,
  };

  // find the best app to show to user
  const bestApp = chooseHealthApp(currentApps);

  if (showPrompts.includes(PromptName.CONTINUE_APP) && bestApp?.status === 'IN_PROGRESS') {
    displayPrompts.push(applyPrompt(PromptName.CONTINUE_APP, { app: bestApp, oeDates }));
  }

  if (showPrompts.includes(PromptName.FINISH_ENROLLING) && bestApp?.status === 'SUBMITTED') {
    if (checkDependencies(PromptName.FINISH_ENROLLING, situations)) {
      displayPrompts.push(applyPrompt(PromptName.FINISH_ENROLLING, { app: bestApp }));
    }
  }

  if (showPrompts.includes(PromptName.APP_SUBMITTED) && bestApp?.status === 'SUBMITTED') {
    if (checkDependencies(PromptName.APP_SUBMITTED, situations)) {
      displayPrompts.push(applyPrompt(PromptName.APP_SUBMITTED, { app: bestApp }));
    }
  }

  /**
   * situational prompts
   * e.g. if situations apply, we show the prompt
   */
  showPrompts.forEach((name) => {
    const prompt = applyPrompt(name, data);

    // for each singleton prompt
    if (prompt && !prompt.forEach && !dismissedNudges?.includes(prompt.name)) {
      if (isDebug || prompt.dependencies.every((dep) => situations[dep])) {
        displayPrompts.push(prompt);
      }
    }
  });

  /**
   * Shows bank link error for any action required bank links
   */
  if (showPrompts.includes(PromptName.BANK_LINK_ERROR)) {
    bankLinks?.forEach((bankLink) => {
      if (bankLink.status === BankLinkStatus.ActionRequired) {
        const prompt = applyPrompt(PromptName.BANK_LINK_ERROR, { bankLink });
        displayPrompts.push(prompt);
      }
    });
  }

  if (showPrompts.includes(PromptName.NEEDS_HEALTH_DOCS)) {
    // health docs
    currentApps.forEach((app) => {
      const issues = [...(app?.dmis || []), ...(app?.svis || [])].filter(
        (issue) => issue.status === 'ACTION_NEEDED' || issue.status === 'PROCESSING',
      );

      const actionNeeded = issues.some((issue) => issue?.status === 'ACTION_NEEDED');

      if (issues.length > 0) {
        displayPrompts.push(
          applyPrompt(PromptName.NEEDS_HEALTH_DOCS, {
            actionNeeded,
            applicationID: app?.id,
            deadline: Date.parse(issues?.[0]?.resolveBy),
          }),
        );
      }
    });
  }

  // payment redirect
  if (showPrompts.includes(PromptName.PAYMENT_REDIRECT)) {
    currentApps.forEach((app) => {
      const policies = [...(app?.policies || [])]
        ?.filter((p) => p.dueDate >= Date.now())
        .sort((a, b) => (a?.policy?.productType > b?.policy?.productType ? 1 : -1));

      policies.forEach((policy) => {
        if (policy.policyStatus === 'ACTIVE_PENDING_PAYMENT') {
          displayPrompts.push(
            applyPrompt(PromptName.PAYMENT_REDIRECT, { policy, enrollment: app.enrollment }),
          );
        }
      });
    });
  }

  if (situations.INCOME && showPrompts.includes(PromptName.INCOME)) {
    incomes?.forEach((income) => {
      if (income?.status === IncomeStatus.Ready) {
        const prompt = applyPrompt(PromptName.INCOME, { income });
        displayPrompts.push(prompt);
      }
    });
  }

  return displayPrompts;
};
