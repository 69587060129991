import React, { useState, useMemo } from 'react';
import { navigate, useSheet, sheets } from '@navigate';
import { loaders } from '@app/config';
import { shallow } from 'zustand/shallow';
import { Loading } from '@uikit';
import { MultiQuestionSplitFormBlueprint } from '@blueprints';
import {
  mutations,
  useQuery,
  useMutation,
  useDeprecatedMutation,
  HealthApplicationQuery,
  UpsertApplicationMembersResponse,
  UpsertApplicationMembersVars,
} from '@data';
import { Segment, SegmentEvent, handleHealthErrors } from '@util';
import { Route } from '@types';
import { getInitialValues, formatPayload } from './sepUtils';
import { SEPType, questions } from './sepFields';
import { SEPIntro } from './SEPIntroView';

const SEP = ({ isGA, applicationID }) => {
  //intro view questions filter subsequent questions we ask
  const [intro, setIntro] = useState(true);
  const [shownQuestions, setShownQuestions] = useState({});

  const { openLoader } = useSheet(
    (state) => ({ openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT) }),
    shallow,
  );

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: submitting }] = useMutation<
    UpsertApplicationMembersResponse,
    UpsertApplicationMembersVars
  >(mutations.UPSERT_APPLICATION_MEMBERS);

  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    onCompleted: () => {
      Segment.track(SegmentEvent.APPLICATION_SEP_COMPLETED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });

      navigate(Route.EDE_REVIEW);
    },
    ...handleHealthErrors,
  });

  const handleScreener = async (values) => {
    setIntro(false);

    const hasSomeAnswer = Object.values(values).some((val) => val === 'YES');

    // only want to update all members in case of 'NO' values
    const payload = Object.fromEntries(Object.entries(values).filter((entry) => entry[1] === 'NO'));

    Segment.track(SegmentEvent.APPLICATION_SEP_STARTED, {
      ...values,
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    const input = {
      applicationID,
      applicationMembersInput: members.map((member) => {
        /**
         * we need to make sure we don't overwrite values for LOSS_OF_MEC if a member has medicaid
         * ending after 3/31/23. to do this, we delete the key from the payload in those cases
         */
        if (
          member?.isMedicaidEnd &&
          member?.medicaidEndDate &&
          member?.medicaidEndDate >= '2023-03-31'
        ) {
          return {
            id: member?.id,
            ...payload,
            [SEPType.isLostCoverageLast60Days]: undefined,
          };
        }

        return {
          id: member?.id,
          ...payload,
        };
      }),
    };

    if (hasSomeAnswer) {
      setIntro(false);
      setShownQuestions(values);
      upsert({ variables: { input } });
    } else {
      await upsert({ variables: { input } });
      openLoader();
      ensure({ variables: { applicationID } });
    }
  };

  // for QLE questions, we're required to show NOT all household members; only those who are
  // requesting coverage and qualify for QHP and APTC
  const { members } = useMemo(() => {
    if (data?.viewerTwo?.health?.application) {
      const { applicant, members } = data?.viewerTwo?.health?.application || {};
      const allMembers = members ? [applicant, ...members] : [applicant];

      return {
        members: allMembers?.filter(
          (m) => m?.preliminaryQHPStatus === 'YES' || m?.preliminaryAPTCStatus === 'YES',
        ),
        allMembers,
      };
    } else {
      return {
        members: [],
        allMembers: [],
      };
    }
  }, [data?.viewerTwo?.health?.application?.members]);

  const filteredQuestions = useMemo(() => {
    return questions.filter((question) => shownQuestions[question.name] === 'YES');
  }, [shownQuestions, questions]);

  return intro ? (
    <SEPIntro
      loading={loading}
      isGA={isGA}
      applicationID={applicationID}
      members={members}
      handleScreener={handleScreener}
    />
  ) : filteredQuestions.length === 0 ? (
    <Loading />
  ) : (
    <MultiQuestionSplitFormBlueprint
      loading={!data}
      isGA={isGA}
      submitting={submitting || ensuring}
      questions={filteredQuestions}
      members={members}
      data={data}
      getInitialValues={getInitialValues}
      onBack={() => setIntro(true)}
      onNext={({ members }, currentQuestion) => {
        return upsert(formatPayload({ applicationID, members, question: currentQuestion.name }));
      }}
      onComplete={() => {
        openLoader();
        ensure({ variables: { applicationID } });
      }}
    />
  );
};

export const SEPView = {
  name: Route.EDE_SEP,
  component: SEP,
  options: {
    ...MultiQuestionSplitFormBlueprint.options,
    title: 'Special Enrollment',
  },
};
