import React, { useMemo, useState } from 'react';

import { SegmentedControl } from '@uikit';
import { Section } from '@layouts';
import { GET_BANK_STATEMENTS, GET_DOCUMENTS, useFintechQuery, useQuery } from '@data';
import { useCopy } from '@app/utils';
import { SettingsLayout } from '@common';
import { DocumentList } from '@app/partial';
import { Route } from '@types';
import { groupDocuments } from '@app/partial/documents';

export const Statements = () => {
  const { c } = useCopy('catch.module.me.StatementsView');
  const [index, setIndex] = useState(0);

  const account = useQuery(GET_DOCUMENTS);
  const bank = useFintechQuery(GET_BANK_STATEMENTS);

  const loading = account.loading || bank.loading;

  // group by month
  const grouped = useMemo(() => {
    const taxDocuments = account.data?.viewerTwo.documents.taxDocuments || [];
    const accountStatements = account.data?.viewerTwo.documents.statements || [];
    const bankStatements = bank.data?.bankStatements || [];

    return {
      forms: groupDocuments(taxDocuments),
      statements: groupDocuments([...bankStatements, ...accountStatements]),
    };
  }, [account.data, bank.data]);

  return (
    <SettingsLayout title={c('title')}>
      <Section>
        <SegmentedControl
          index={index}
          setIndex={setIndex}
          controls={[{ title: 'Forms' }, { title: 'Statements' }]}
        />
        {index === 0 && (
          <DocumentList
            loading={loading}
            documentsGroup={grouped.forms}
            emptyHint={c('emptyHintForms')}
          />
        )}
        {index === 1 && (
          <DocumentList
            loading={loading}
            documentsGroup={grouped.statements}
            emptyHint={c('emptyHintStatements')}
          />
        )}
      </Section>
    </SettingsLayout>
  );
};

export const StatementsView = {
  name: Route.STATEMENTS,
  component: Statements,
  options: {
    title: 'Documents',
    largeTitle: true,
  },
};
