import { PromptCard } from '@app/_common';
import { usePlaid } from '../hooks';

export interface BankLinkErrorCardProps {
  bankLinkId: string;
}

export function BankLinkErrorCard(props) {
  const bankLinkId = props.data?.bankLinkId;

  const { disabled, loading, openLink } = usePlaid({
    bankLinkId,
    onSuccess: () => {},
  });

  return (
    <PromptCard
      {...props}
      loading={loading}
      disabled={disabled}
      actions={[
        {
          label: 'Fix',
          type: 'CUSTOM',
          onAction: () => openLink(),
        },
      ]}
    />
  );
}
