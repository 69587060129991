import { LogRocket } from './logrocket';
import { AnalyticsClient } from './types';
import { Segment } from '@app/utils';

export const Analytics: AnalyticsClient = {
  init: () => {
    LogRocket.init();
  },
  identify: (userId, traits) => {
    LogRocket.identify(userId, traits);

    // @todo: move segment into same format
    Segment.identifyUser(userId, traits);
  },
  page: () => {
    Segment.pageView();
  },
};
