import { gql } from '../generated';

gql(`
  fragment Flags on FeatureFlags {
    id
    hasAccessToFintech
    hasAccessToPhase3
  }
`);

export const GET_FEATURE_FLAGS = gql(`
  query GetFeatureFlags {
    me {
      id
      flags {
        ...Flags
      }
    }
  }
`);
