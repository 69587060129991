import { useMutation, CreatePresignedUrl, DocFileType } from '@data';

export enum FileType {
  Heic = 'HEIC',
  HeicMime = 'image/heic',
  Heif = 'HEIF',
  HeifMime = 'image/heif',
  Jpeg = 'JPEG',
  JpegMime = 'image/jpeg',
  Jpg = 'JPG',
  Pdf = 'application/pdf',
  Png = 'PNG',
  PngMime = 'image/png',
}

export type SupportedFileType =
  | FileType.Heic
  | FileType.HeicMime
  | FileType.Heif
  | FileType.HeifMime
  | FileType.Jpeg
  | FileType.JpegMime
  | FileType.Jpg
  | FileType.Pdf
  | FileType.Png
  | FileType.PngMime;

const FILE_TYPE_TO_DOC_FILE_TYPE: Record<SupportedFileType, DocFileType> = {
  [FileType.Heic]: DocFileType.Heic,
  [FileType.HeicMime]: DocFileType.Heic,
  [FileType.Heif]: DocFileType.Heif,
  [FileType.HeifMime]: DocFileType.Heif,
  [FileType.Jpeg]: DocFileType.Jpeg,
  [FileType.JpegMime]: DocFileType.Jpeg,
  [FileType.Jpg]: DocFileType.Jpeg,
  [FileType.Pdf]: DocFileType.Pdf,
  [FileType.Png]: DocFileType.Png,
  [FileType.PngMime]: DocFileType.Png,
};

const mimeTypes = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
  HEIC: 'image/heic',
  HEIF: 'image/heif',
};

// 10MB
const DEFAULT_MAX = 10000000;

export interface UploadData {
  fileKey: string;
  fileType: DocFileType;
}

export interface UploadOptions {
  onStart: () => void;
  onError: (err: string) => void;
  onCompleted: (data: UploadData) => void;
}

export interface UploadProps {
  name: string;
  file: File;
  options: UploadOptions;
}

export interface UseUploadProps {
  filePrefix: string;
  // @todo limit to FileType from graphql, and then map to mime types
  fileTypes?: SupportedFileType[];
  onSuccess: (data: UploadData) => void;
}

export function useUpload({
  filePrefix,
  fileTypes = Object.keys(FILE_TYPE_TO_DOC_FILE_TYPE),
  onSuccess,
}: UseUploadProps): {
  upload: (props: UploadProps) => void;
} {
  const [createPresignedUrl] = useMutation(CreatePresignedUrl);

  const validateFile = (file, options): boolean => {
    if (file?.size > DEFAULT_MAX) {
      options.onError('File is too large');
      return false;
    }

    if (!fileTypes.includes(file?.type)) {
      options.onError('Invalid format');
      return false;
    }

    return true;
  };

  const upload = async ({ file, options }) => {
    options?.onStart();
    const isValid = validateFile(file, options);

    if (isValid) {
      const fileType = FILE_TYPE_TO_DOC_FILE_TYPE[file.type];
      const fileName = `${filePrefix}-${Date.now()}.${fileType}`;
      const mimeType = mimeTypes[fileType];

      const uploadData: UploadData = {
        fileKey: fileName,
        fileType,
      };

      // generate the presigned URL
      createPresignedUrl({
        variables: {
          input: { fileName, fileType: mimeType },
        },
        onCompleted: async (data) => {
          const presignedUrl = data?.createPresignedUrl?.url;

          try {
            await fetch(presignedUrl, {
              method: 'PUT',
              headers: { 'Content-Type': mimeType },
              body: file,
            });

            options.onCompleted(uploadData);
            onSuccess(uploadData);
          } catch (e) {
            options.onError('Something went wrong. Try again.');
          }
        },
        onError: () => {
          console.log('error fetching presigned url');
          options.onError('Something went wrong. Try again.');
        },
      });
    }
  };

  return { upload };
}
