import React from 'react';
import { Asset, Banner, Modal } from '@app/_ui-kit';
import { useModal } from '@app/hooks';
import { BasicFormBlueprint } from '@app/blueprints';
import { useCopy } from '@app/utils';
import Close from '@svg/close.svg';

import { removeApplicantFields } from './confirmApplicantsFields';
import { mutations, updates, useMutation } from '@app/data';

const ConfirmApplicantsMenu = ({ values, remove }) => {
  const { c } = useCopy('catch.ede');
  const { isOpen, openModal, closeModal } = useModal();

  const removeFromForm = () => {
    remove();
    closeModal();
  };

  const [handleAppRemoval, { loading: removing }] = useMutation(
    mutations.REMOVE_APPLICATION_MEMBER,
    {
      onCompleted: () => removeFromForm(),
    },
  );

  // if the member exists, we need to trigger a mutation to remove them.
  // otherwise, we can just remove the member from the form immediately
  const handleRemove = (values) => {
    if (values?.id) {
      handleAppRemoval({
        variables: {
          input: {
            id: values.id,
            removeDate: values.removeDate,
            removeReason: values.removeReason,
          },
        },
        update: (cache) =>
          updates.REMOVE_APPLICATION_MEMBER(cache, {
            memberID: values?.id,
            applicationID: values?.applicationID,
          }),
      });
    } else {
      removeFromForm();
    }
  };

  return (
    <>
      <Asset
        containerSize="xs"
        size="glyph"
        svg={Close}
        bg="skeleton"
        shape="circle"
        // note: asset is still triggering this when disabled
        onPress={values?.relation === 'SELF' ? undefined : openModal}
        disabled={values?.relation === 'SELF'}
      />
      <Modal isOpen={isOpen} onRequestClose={closeModal}>
        <BasicFormBlueprint
          submitting={removing}
          title={c(
            values?.fullName
              ? 'RemoveDependentPrompt.title'
              : 'RemoveDependentPrompt.relationTitle',
            {
              name: values?.fullName,
              relation: values?.relation,
            },
          )}
          subtitles={[
            <Banner
              key="banner"
              bg="coverageLight"
              title={c('RemoveDependentPrompt.subtitle', {
                relation: values?.fullName ? undefined : values?.relation,
              })}
            />,
          ]}
          button="Remove"
          onBack={closeModal}
          formConfig={{
            fields: values?.id ? removeApplicantFields : [],
            initialValues: {
              id: values?.id,
              fullName: values?.fullName || 'this person',
              applicationID: values?.applicationID,
              relation: values?.relation,
            },
            onSubmit: handleRemove,
          }}
        />
      </Modal>
    </>
  );
};

export default ConfirmApplicantsMenu;
