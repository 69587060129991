import React, { useMemo } from 'react';

import { ComplexRow } from '@uikit';
import { AccountStatementFragment, BankStatementFragment, TaxFormFragment } from '@data';
import { useDocument } from '@app/hooks/useDocument';

export const get_labels = ({ type, detail }) => {
  let label, sublabel;
  switch (type) {
    case 'TAX_1040_ES':
      label = `Form 1040-ES (${detail})`;
      sublabel = 'Estimated Tax Payment';
      break;
    case 'TAX_1095_A':
      label = `Form 1095-A (${detail})`;
      sublabel = 'Health Marketplace Statement';
      break;
    case 'TAX_1099_R':
      label = 'Form 1099-R';
      sublabel = 'IRA Contributions';
      break;
    case 'TAX_5498':
      label = 'Form 5498';
      sublabel = 'IRA Contributions';
      break;
    case 'DDA':
      label = detail;
      sublabel = 'Bank Statement';
      break;
    case 'IRA':
      label = `Retirement`;
      sublabel = 'IRA Statement';
      break;
    default:
      break;
  }

  return {
    label,
    sublabel,
  };
};

export function BankStatementRow({ doc }: { doc: BankStatementFragment }) {
  const downloadStatement = () => {
    if (doc.link) {
      window.open(doc.link, '_blank');
    }
  };

  return (
    <ComplexRow
      testID={doc.id}
      onPress={downloadStatement}
      label="Catch Money"
      sublabel="Bank Statement"
    />
  );
}

export function DocumentRow({ doc }: { doc: TaxFormFragment | AccountStatementFragment }) {
  const { downloadDocument } = useDocument(doc);

  const { label, sublabel } = useMemo(
    () =>
      get_labels({
        type: doc.type,
        detail: doc.detail,
      }),
    [document],
  );

  return (
    <ComplexRow testID={doc.id} onPress={downloadDocument} label={label} sublabel={sublabel} />
  );
}
