import { isBefore } from 'date-fns';
import { optionPresets } from '@app/forms';
import { Env, getFullName, sixtyFiveYearsAgo, toDate } from '@app/utils';

const isValidDate = (date) => {
  const d = new Date(date);
  return /^\d{4}-\d{2}-\d{2}$/.test(date) && !isNaN(d.getTime());
};

export const fields = [
  {
    testID: '27',
    copy: 'catch.ede',
    name: 'isRequestingCoverage',
    type: 'radio',
    options: optionPresets.YES_NO_TRINARY,
    required: true,
    label: { id: 'HealthEnrollment.27_Q' },
    help: { id: 'HealthEnrollment.27_T' },
  },

  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'name',
    type: 'legalName',
    required: true,
    label: { id: '4_Q' },
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      nameSuffix: true,
    },
  },

  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'dob',
    type: 'date',
    dob: true,
    required: true,
    label: { id: '33_L' },
    sublabel: { id: '33_SL' },
    format: 'YYYY-MM-DD',
    dependencies: ['dob', 'name'],
    fieldAddition: ({ dob, name }) => {
      if (isValidDate(dob) && isBefore(toDate(dob), sixtyFiveYearsAgo())) {
        return { component: 'APPLICANT_AGE_WARNING', props: name };
      }
    },
  },
  {
    testID: '31',
    copy: 'catch.ede.HealthEnrollment',
    name: 'sex',
    dependencies: ['isGA'],
    type: 'dropdown',
    required: true,
    label: { id: '31_Q' },
    // help: ({ isGA }) => ({ id: `31_T${isGA ? '_GA' : ''}` }),
    options: [
      { label: 'Female', value: 'FEMALE' },
      { label: 'Male', value: 'MALE' },
    ],
  },
  {
    testID: '32',
    copy: 'catch.ede.HealthEnrollment',
    name: 'ssn',
    type: 'ssn',
    dependencies: ['isRequestingCoverage', 'name'],
    optional: (values) => values.isRequestingCoverage === 'NO',
    label: { id: '32_L' },
    help: (values) => ({
      id: values.isRequestingCoverage === 'YES' ? '32_T' : '34_T',
      data: { name: getFullName(values.name, 'this person') },
    }),
    editable: true,
  },
  {
    testID: '32_SKIP',
    copy: 'catch.ede.HealthEnrollment',
    name: 'confirmMissingSSN',
    type: 'checkbox',
    dependencies: ['isRequestingCoverage', 'ssn', 'name'],
    display: ({ ssn, isRequestingCoverage }) => !ssn && isRequestingCoverage === 'YES',
    label: (values) => ({
      id: '32_CONFIRM',
      data: { fullName: getFullName(values?.name, 'This person') },
    }),
    required: ({ ssn }) => !ssn,
  },

  {
    name: 'contact',
    type: 'section',
    title: 'Contact Information',
    fields: [
      {
        testID: '9',
        copy: 'catch.ede.HealthEnrollment',
        name: 'email',
        type: 'email',
        required: true,
        label: { id: '9_L' },
        validate: (value) => {
          if (
            !/^(?![._-])(?!.*[._-]{2,})([a-zA-Z0-9]+[._-]?)*[a-zA-Z0-9]+@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/.test(
              value,
            )
          ) {
            return Env.isProd
              ? 'Emails can only contain numbers, letters, and . _ or -'
              : 'Emails can only contain numbers, letters, and . _ or -. (dev only msg: this was a rule implemented by CMS in SES R37.1 which ignored guidance on best practices)';
          }
        },
      },
      {
        testID: '14',
        copy: 'catch.ede.HealthEnrollment',
        name: 'phone',
        type: 'phone',
        required: true,
        prefix: true,
        label: { id: '14_Q' },
        subfields: {
          number: true,
          ext: true,
          type: true,
        },
      },
      {
        testID: '14a',
        copy: 'catch.ede.HealthEnrollment',
        name: 'secondaryPhone',
        type: 'phone',
        required: false,
        prefix: true,
        label: { id: '14a_Q' },
        subfields: {
          number: true,
          ext: true,
          type: true,
        },
      },
      {
        copy: 'catch.fields.textOptIn',
        name: 'textOptIn',
        type: 'checkbox',
        title: { id: 'label' },
        label: { id: 'disclaimer' },
      },
      {
        testID: '5',
        copy: 'catch.ede.HealthEnrollment',
        name: 'homeAddress',
        type: 'address',
        required: true,
        label: { id: '5_Q' },
        help: { id: '5_T' },
        dependencies: ['coverageState', 'homeAddress'],
        disabledFields: ({ coverageState, homeAddress }) => {
          return {
            state: coverageState && coverageState === homeAddress?.state,
          };
        },
        fieldAddition: ({ coverageState, homeAddress }) => {
          if (coverageState && coverageState === homeAddress?.state) {
            return {
              component: 'TEXT',
              props: {
                copy: { id: 'ede.HealthEnrollment.5_warning', data: { state: coverageState } },
              },
            };
          }
        },
      },
      {
        testID: '7',
        copy: 'catch.ede',
        name: 'mailingAddressOption',
        type: 'radio',
        required: true,
        allowFalseValues: true,
        options: [
          { label: 'Same as home address', value: true },
          { label: 'Different address', value: false },
        ],
        label: { id: 'HealthEnrollment.7_Q' },
        help: { id: 'HealthEnrollment.7_T' },
      },
      {
        testID: '8',
        copy: 'catch.ede.HealthEnrollment',
        name: 'mailingAddress',
        type: 'address',
        required: true, // required when showing
        dependencies: ['mailingAddressOption'],
        display: (values) => values.mailingAddressOption === false,
        allowPOBox: true,
        label: { id: '8_Q' },
        help: { id: '8_T' },
      },
    ],
  },

  {
    name: 'comms-section',
    type: 'section',
    title: 'Communication Preferences',
    fields: [
      {
        testID: '10a',
        copy: 'catch.ede.HealthEnrollment',
        name: 'writtenLanguageType',
        dependencies: ['isGA'],
        type: 'dropdown',
        options: optionPresets.LANGUAGES,
        required: true,
        label: { id: '10a_Q' },
        help: ({ isGA }) => ({ id: `10a_T${isGA ? '_GA' : ''}` }),
      },
      {
        testID: '10b',
        copy: 'catch.ede.HealthEnrollment',
        name: 'spokenLanguageType',
        type: 'dropdown',
        options: optionPresets.LANGUAGES,
        required: true,
        label: { id: '10b_Q' },
      },
      {
        testID: '11',
        copy: 'catch.ede.HealthEnrollment',
        name: 'contactMethod',
        type: 'radio',
        options: [
          { label: "Email or text me when there's a new notice", value: 'EMAIL' },
          { label: 'Send me paper notices in the mail', value: 'MAIL' },
        ],
        required: true,
        label: { id: '11_Q' },
        help: { id: '11_T' },
      },
      {
        testID: '12',
        copy: 'catch.ede.HealthEnrollment',
        name: 'digitalContactMethod',
        type: 'radio',
        dependencies: ['isGA', 'email', 'contactMethod'],
        display: ({ contactMethod }) => contactMethod === 'EMAIL',
        options: ({ isGA, email }) =>
          isGA
            ? [{ label: 'Email', value: 'EMAIL' }]
            : [
                { label: email ? `Email me at ${email}` : 'Email', value: 'EMAIL' },
                { label: 'Text me', value: 'E_TEXT' },
              ],
        required: true,
        label: ({ isGA }) => ({ id: `12_Q${isGA ? '_GA' : ''}` }),
        help: { id: '12_T' },
        fieldAddition: () => ({
          component: 'TEXT',
          props: { copy: { id: 'ede.text-policy' } },
        }),
      },
      {
        testID: '13',
        copy: 'catch.ede.HealthEnrollment',
        name: 'mobileNotificationOption',
        type: 'radio',
        dependencies: ['digitalContactMethod', 'phone'],
        display: ({ digitalContactMethod, phone }) => {
          return phone.type === 'MOBILE' && !!phone.number && digitalContactMethod === 'E_TEXT';
        },
        options: ({ phone }) => [
          { label: phone.number, value: 'EXISTING' },
          { label: 'A different mobile number', value: 'OTHER' },
        ],
        required: true,
        label: { id: '13_Q' },
      },
      {
        testID: '14',
        copy: 'catch.ede.HealthEnrollment',
        name: 'mobileNotificationPhoneNumber',
        type: 'phone',
        required: true,
        dependencies: ['mobileNotificationOption', 'digitalContactMethod', 'phone'],
        display: ({ mobileNotificationOption, digitalContactMethod, phone }) => {
          const hasExisting = !!phone?.number && phone?.type === 'MOBILE';
          return (
            digitalContactMethod === 'E_TEXT' &&
            (!hasExisting || mobileNotificationOption === 'OTHER')
          );
        },
      },
    ],
  },
];
