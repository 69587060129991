import { gql } from '../generated';

gql(`
  fragment BankStatement on BankStatement {
    id
    link
    period
  }  
`);

gql(`
  fragment TaxForm on TaxForm {
    id
    date
    providerId
    documentType
    type
    detail
    month
    vertical
    planType
  }  
`);

gql(`
  fragment AccountStatement on AccountStatement {
    id
    date
    providerId
    documentType
    type
    detail
    month
    vertical
    planType
  }  
`);

// fintech statements
export const GET_BANK_STATEMENTS = gql(`
  query GetBankStatements {
    bankStatements {
      ...BankStatement
    }
  }
`);

// fintech statements
export const GET_DOCUMENTS = gql(`
  query GetDocuments {
    viewerTwo {
      id
      documents {
        statements {
          ...AccountStatement
        }
        taxDocuments {
          ...TaxForm
        }
      }
    }
  }
`);
