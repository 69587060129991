import React from 'react';
import { optionPresets } from '@app/forms';
import { getFullName, useCopy } from '@app/utils';
import { useSheet } from '@app/navigate';
import { Link } from '@uikit';

const shorthand = {
  PERMANENT_RESIDENT_CARD_I_551: 'I-551',
  MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE: 'passport',
  EMPLOYMENT_AUTHORIZATION_CARD_I_766: 'card',
  ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94: 'passport',
  FOREIGN_PASSPORT: 'passport',
};

export const followUpFields = [
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '75',
    name: 'lawfulPresenceGrantDate',
    type: 'date',
    format: 'YYYY-MM-DD',
    dependencies: ['uiQuestionsToDisplay', 'fullName'],
    display: (values) => values?.uiQuestionsToDisplay?.['75'],
    label: ({ fullName }) => ({ id: '75_Q', data: { name: fullName } }),
    help: { id: '75_T' },
  },
];

const Statuses = () => {
  const { c } = useCopy('catch.ede.HealthEnrollment');
  return (
    <Link
      testID="statuses-info"
      onPress={() =>
        useSheet.getState().open('HELP_TEXT', {
          subtitle: c('44_T'),
        })
      }
    >
      this list of statuses
    </Link>
  );
};

export const fields = [
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'dacaAlert',
    type: 'label',
    dependencies: ['dacaIneligible', 'coverageState', 'fullName'],
    display: ({ dacaIneligible }) => !!dacaIneligible,
    label: ({ coverageState, fullName }) => ({
      id: '44_DACA_alert',
      data: { coverageState, fullName },
    }),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '45',
    name: 'isLawfulPresenceStatus',
    required: true,
    type: 'option',
    dependencies: [
      'uiQuestionsToDisplay',
      'isLawfulPresenceStatus',
      'fullName',
      'isRequestingFinancialAssistance',
      'dacaIneligible',
    ],
    options: ({ fullName, isRequestingFinancialAssistance }) => [
      {
        label: { id: '44_yes', data: { name: fullName } },
        value: 'YES',
      },
      {
        label: {
          id: '44_no',
          data: { name: fullName, fa: isRequestingFinancialAssistance ? 'YES' : 'NO' },
        },
        value: 'NO',
      },
    ],
    label: ({ fullName }) => ({ id: '44_Q', data: { name: fullName } }),
    help: ({ dacaIneligible }) => ({ id: dacaIneligible ? '44_T_DACA_ineligible' : '44_T' }),
    display: (values) => values?.uiQuestionsToDisplay?.['45'],
    fieldAddition: ({ isLawfulPresenceStatus, fullName, isRequestingFinancialAssistance }) => {
      if (isLawfulPresenceStatus === 'NO') {
        return {
          component: 'TEXT',
          props: {
            copy: {
              id: 'ede.HealthEnrollment.44_warning',
              data: {
                name: fullName,
                fa: isRequestingFinancialAssistance ? 'YES' : 'NO',
                statuses: <Statuses />,
              },
            },
          },
        };
      }
    },
  },
  {
    copy: 'catch.ede',
    testID: '46', // @todo let sam know this has been updated
    name: 'lawfulPresenceDocumentation',
    type: 'dropdown',
    options: optionPresets.LAWFUL_PRESENCE_DOCUMENT_TYPE,
    dependencies: ['isLawfulPresenceStatus', 'fullName'],
    required: true,
    display: (values) => values.isLawfulPresenceStatus === 'YES',
    label: ({ fullName }) => ({ id: 'HealthEnrollment.46_Q', data: { name: fullName } }),
  },
  {
    // numbers only; 9 characters
    copy: 'catch.ede.HealthEnrollment',
    name: 'alienNumber',
    type: 'text',
    characters: /\D+/g,
    length: 9,
    label: { id: '42.alienNumber_L' },
    help: (values) => (values?.isNaturalizedCitizen === 'YES' ? { id: '43_T' } : undefined), // if naturalized citizen
    dependencies: [
      'isLawfulPresenceStatus',
      'lawfulPresenceDocumentation',
      'isNaturalizedCitizen',
      'lawfulPresenceDocumentation',
      'fullName',
    ],
    display: (values) => {
      const showForLawfulPresence =
        values.isLawfulPresenceStatus === 'YES' &&
        [
          'PERMANENT_RESIDENT_CARD_I_551',
          'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
          'REENTRY_PERMIT',
          'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
          'EMPLOYMENT_AUTHORIZATION_CARD_I_766',
          'REFUGEE_TRAVEL_DOCUMENT',
          'NOTICE_OF_ACTION_I_797',
          'OTHER',
        ].includes(values?.lawfulPresenceDocumentation);

      const showForNaturalizedCitizen =
        values?.isNaturalizedCitizen === 'YES' &&
        ['CERTIFICATE_OF_CITIZENSHIP', 'CERTIFICATE_OF_NATURALIZATION'].includes(
          values?.lawfulPresenceDocumentation,
        );

      return showForLawfulPresence || showForNaturalizedCitizen;
    },
  },
  {
    // alphanumeric, 11 characters; letter can only be in 10th position
    copy: 'catch.ede.HealthEnrollment',
    name: 'i94Number',
    type: 'text',
    characters: /[^\w]|_/g, // alphanumeric
    length: 11,
    regex: {
      value: /^[0-9]{9}[a-zA-Z0-9][0-9]$/,
      message: 'Letter should be in second to last spot',
    },
    label: { id: '42.i94Number_L' },
    dependencies: ['isLawfulPresenceStatus', 'lawfulPresenceDocumentation'],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        [
          'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94',
          'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
          'NOTICE_OF_ACTION_I_797',
          'FOREIGN_PASSPORT',
          'ANOTHER_DOCUMENT',
          'OTHER',
        ].includes(values?.lawfulPresenceDocumentation)
      );
    },
  },
  {
    // numerical, 10 characters
    copy: 'catch.ede.HealthEnrollment',
    name: 'sevisID',
    type: 'text',
    characters: /\D+/g,
    length: 10,
    // regex: {
    //   value: /[0-9]/,
    //   message: 'Numerals only',
    // },
    label: { id: '42.sevisIDNum_L' },
    dependencies: ['isLawfulPresenceStatus', 'lawfulPresenceDocumentation'],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        [
          'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94',
          'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
          'FOREIGN_PASSPORT',
        ].includes(values?.lawfulPresenceDocumentation)
      );
    },
  },
  {
    // alphanumeric; 3 letters followed by 10 numbers (13 total)
    copy: 'catch.ede.HealthEnrollment',
    name: 'cardNumber',
    type: 'text',
    characters: /[^\w]|_/g, // alphanumeric
    length: 13,
    regex: {
      value: /[a-zA-Z]{3}[0-9]{10}/,
      message: 'Must be 3 letters followed by 10 numbers',
    },
    label: { id: '42.cardNumber_L' },
    dependencies: ['isLawfulPresenceStatus', 'lawfulPresenceDocumentation'],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        ['PERMANENT_RESIDENT_CARD_I_551', 'EMPLOYMENT_AUTHORIZATION_CARD_I_766'].includes(
          values?.lawfulPresenceDocumentation,
        )
      );
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'passportNumber',
    type: 'text',
    characters: /[^\w]|_/g, // alphanumeric
    minLength: 6,
    maxLength: 12,
    label: { id: '42.passportNumber_L' },
    dependencies: ['isLawfulPresenceStatus', 'lawfulPresenceDocumentation'],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        [
          'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
          'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
          'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
          'FOREIGN_PASSPORT',
        ].includes(values?.lawfulPresenceDocumentation)
      );
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'passportIssuingCountry',
    type: 'dropdown',
    options: optionPresets.NON_USA_COUNTRIES,
    label: { id: '42.passportIssuingCountry_L' },
    dependencies: ['isLawfulPresenceStatus', 'lawfulPresenceDocumentation'],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        [
          'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
          'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
          'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
          'FOREIGN_PASSPORT',
        ].includes(values?.lawfulPresenceDocumentation)
      );
    },
  },

  {
    // 3 characters: letter + 2 numbers
    copy: 'catch.ede.HealthEnrollment',
    name: 'employmentAuthorizationCategoryIdentifier',
    type: 'text',
    characters: /[^\w]|_/g, // alphanumeric
    length: 3,
    regex: {
      value: /[a-zA-Z][0-9]{2}/,
      message: 'Must be letter followed by two numbers, like A12',
    },
    label: { id: '42.categoryCode_L' },
    dependencies: ['isLawfulPresenceStatus', 'lawfulPresenceDocumentation'],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        ['EMPLOYMENT_AUTHORIZATION_CARD_I_766'].includes(values?.lawfulPresenceDocumentation)
      );
    },
  },

  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isSameNameApplicationAndDocument',
    type: 'option',
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isLawfulPresenceStatus',
      'lawfulPresenceDocumentation',
      'fullName',
      'givenName',
      'middleName',
      'familyName',
      'nameSuffix',
    ],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        [
          'PERMANENT_RESIDENT_CARD_I_551',
          'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
          'EMPLOYMENT_AUTHORIZATION_CARD_I_766',
          'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
          'FOREIGN_PASSPORT',
        ].includes(values.lawfulPresenceDocumentation)
      );
    },
    label: ({ fullName, lawfulPresenceDocumentation }) => {
      return {
        id: '70_Q',
        data: { name: fullName, document: shorthand[lawfulPresenceDocumentation] },
      };
    },
    sublabel: ({ givenName, middleName, familyName, nameSuffix }) => {
      return `${givenName}${middleName ? ` ${middleName} ` : ' '}${familyName} ${nameSuffix || ''}`;
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'documentAlternativeName',
    type: 'legalName',
    prefix: true,
    required: true,
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      nameSuffix: true,
    },
    dependencies: [
      'isLawfulPresenceStatus',
      'lawfulPresenceDocumentation',
      'isSameNameApplicationAndDocument',
    ],
    display: (values) =>
      values.isLawfulPresenceStatus === 'YES' &&
      values.isSameNameApplicationAndDocument === 'NO' &&
      [
        'PERMANENT_RESIDENT_CARD_I_551',
        'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
        'EMPLOYMENT_AUTHORIZATION_CARD_I_766',
        'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
        'FOREIGN_PASSPORT',
      ].includes(values.lawfulPresenceDocumentation),
    label: ({ lawfulPresenceDocumentation }) => ({
      id: '70.fullNameInput_Q',
      data: { document: shorthand[lawfulPresenceDocumentation] },
    }),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'documentExpirationDate',
    type: 'date',
    format: 'YYYY-MM-DD',
    label: { id: '42.documentExpirationDate_L' },
    dependencies: ['isLawfulPresenceStatus', 'lawfulPresenceDocumentation'],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        [
          'PERMANENT_RESIDENT_CARD_I_551',
          'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
          'REENTRY_PERMIT',
          'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
          'EMPLOYMENT_AUTHORIZATION_CARD_I_766',
          'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94',
          'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
          'REFUGEE_TRAVEL_DOCUMENT',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
          'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
          'FOREIGN_PASSPORT',
        ].includes(values?.lawfulPresenceDocumentation)
      );
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'otherTypeText',
    type: 'text',
    label: { id: '42.documentDescription_L' },
    dependencies: ['isLawfulPresenceStatus', 'lawfulPresenceDocumentation'],
    display: (values) => {
      return (
        values.isLawfulPresenceStatus === 'YES' &&
        ['OTHER'].includes(values?.lawfulPresenceDocumentation)
      );
    },
  },
  {
    testID: '72',
    copy: 'catch.ede.HealthEnrollment',
    name: 'lawfulPresenceAlternateDocumentation',
    type: 'multiSelect', // @todo, again probably a simpler situation here
    dependencies: [
      'isLawfulPresenceStatus',
      'lawfulPresenceDocumentation',
      'alienNumber',
      'fullName',
    ],
    options: ({ lawfulPresenceDocumentation, alienNumber }) => {
      // should exclude the option for I-94 if that has been specified above
      const options = optionPresets.LAWFUL_PRESENCE_ALTERNATION_DOCUMENTATION;

      const hasDoc = !!lawfulPresenceDocumentation && lawfulPresenceDocumentation === 'OTHER';

      // filter out alien # option if already provided
      return hasDoc ? options.filter((o) => o.value !== 'ALIEN_NUM') : options;
    },
    display: ({ isLawfulPresenceStatus, lawfulPresenceDocumentation }) =>
      isLawfulPresenceStatus === 'YES' && !!lawfulPresenceDocumentation,
    label: ({ fullName }) => ({ id: '71_Q', data: { name: fullName } }),
    help: { id: '71_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'otherDocumentTypeText',
    type: 'text',
    label: { id: '42.documentDescription_L' },
    dependencies: [
      'isLawfulPresenceStatus',
      'lawfulPresenceDocumentation',
      'lawfulPresenceAlternateDocumentation',
    ],
    display: (values) =>
      values.isLawfulPresenceStatus === 'YES' &&
      values.lawfulPresenceDocumentation !== 'OTHER' &&
      values?.lawfulPresenceAlternateDocumentation?.includes('ALIEN_NUM'),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'otherDocumentAlienNumber',
    type: 'text',
    dependencies: [
      'isLawfulPresenceStatus',
      'lawfulPresenceDocumentation',
      'lawfulPresenceAlternateDocumentation',
      'fullName',
    ],
    display: (values) =>
      values.isLawfulPresenceStatus === 'YES' &&
      values.lawfulPresenceDocumentation !== 'OTHER' &&
      values?.lawfulPresenceAlternateDocumentation?.includes('ALIEN_NUM'),
    label: ({ fullName }) => ({ id: '63_Q', data: { name: fullName } }),
    help: { id: '63_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'otherDocumentI94Number',
    type: 'text',
    dependencies: [
      'isLawfulPresenceStatus',
      'lawfulPresenceDocumentation',
      'lawfulPresenceAlternateDocumentation',
      'fullName',
    ],
    display: (values) =>
      values.isLawfulPresenceStatus === 'YES' &&
      values.lawfulPresenceDocumentation !== 'OTHER' &&
      values?.lawfulPresenceAlternateDocumentation?.includes('ALIEN_NUM'),
    label: ({ fullName }) => ({ id: '63.I-94_Q', data: { name: fullName } }),
    help: { id: '63.I-94_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '72',
    name: 'hasLivedInUS5Years',
    type: 'option',
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isLawfulPresenceStatus', 'bornBeforeAug1996', 'fullName', 'isGA'],
    display: ({ isLawfulPresenceStatus, bornBeforeAug1996 }) =>
      isLawfulPresenceStatus === 'YES' && bornBeforeAug1996,
    label: ({ fullName }) => ({ id: '72_Q', data: { name: fullName } }),
    help: { id: '72_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '73',
    name: 'isVeteran',
    type: 'multiSelect',
    dependencies: [
      'hasLivedInUS5Years',
      'bornBeforeAug1996',
      'isLawfulPresenceStatus',
      'age',
      'relation',
      'fullName',
      'married',
      'self',
      'spouse',
      'isGA',
    ],
    display: ({ hasLivedInUS5Years, bornBeforeAug1996, isLawfulPresenceStatus }) =>
      hasLivedInUS5Years === 'NO' || (!bornBeforeAug1996 && isLawfulPresenceStatus === 'YES'),
    options: ({ age, relation, fullName, married, self, spouse }) => {
      let options = [];

      // add member as an option
      if (age > 17) {
        options.push({ label: fullName, value: 'SELF' });
      }

      // check for spouse
      if (relation === 'SELF' && !!spouse) {
        options.push({ label: getFullName(spouse), value: 'SPOUSE' });
      } else if (relation === 'SPOUSE' && !!self) {
        options.push({ label: getFullName(self), value: 'SPOUSE' });
      } else if (relation === 'CHILD' && married) {
        options.push({ label: `${fullName}'s spouse`, value: 'SPOUSE' });
      } else if (age > 14) {
        options.push({ label: `${fullName}'s deceased spouse`, value: 'DECEASED_SPOUSE' });
      }

      if (relation === 'CHILD') {
        if (!!self) options.push({ label: getFullName(self), value: 'PARENT_STEP_PARENT_1' });
        if (!!spouse) options.push({ label: getFullName(spouse), value: 'PARENT_STEP_PARENT_2' });
      }

      return options;
    },
    label: { id: '73_Q' },
    help: { id: '73_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '75',
    name: 'lawfulPresenceGrantDate',
    type: 'date',
    format: 'YYYY-MM-DD',
    dependencies: ['uiQuestionsToDisplay', 'fullName', 'isGA'],
    display: (values) => values?.uiQuestionsToDisplay?.['75'],
    label: ({ fullName }) => ({ id: '75_Q', data: { name: fullName } }),
    help: { id: '75_T' },
  },

  // below here are the naturalized citizen questions
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '40',
    name: 'isNaturalizedCitizen',
    required: true,
    type: 'option',
    dependencies: ['uiQuestionsToDisplay', 'fullName'],
    options: optionPresets.YES_NO_TRINARY,
    label: ({ fullName }) => ({ id: '40_Q', data: { name: fullName } }),
    help: { id: '40_T' },
    display: (values) => values?.uiQuestionsToDisplay?.['40'],
  },
  {
    copy: 'catch.ede',
    testID: '41',
    name: 'lawfulPresenceDocumentation',
    type: 'dropdown',
    dependencies: ['isNaturalizedCitizen', 'fullName'],
    display: (values) => values?.isNaturalizedCitizen === 'YES',
    options: optionPresets.IMMIGRATION_DOCUMENTS,
    label: ({ fullName }) => ({ id: 'HealthEnrollment.41_Q', data: { name: fullName } }),
    help: { id: 'HealthEnrollment.41_T' },
  },

  {
    testID: '42',
    name: 'naturalizationCertificateNumber',
    type: 'text',
    dependencies: ['isNaturalizedCitizen', 'lawfulPresenceDocumentation', 'fullName'],
    display: ({ lawfulPresenceDocumentation, isNaturalizedCitizen }) =>
      lawfulPresenceDocumentation === 'CERTIFICATE_OF_NATURALIZATION' &&
      isNaturalizedCitizen === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: { id: '42_Q' },
    help: { id: '42_T' },
  },
  {
    // alphanumeric: 6-12 characters
    testID: '43',
    name: 'citizenshipNumber',
    type: 'text',
    characters: /[^\w]|_/g, // alphanumeric
    minLength: 6,
    maxLength: 12,
    dependencies: ['isNaturalizedCitizen', 'lawfulPresenceDocumentation', 'fullName'],
    display: ({ lawfulPresenceDocumentation, isNaturalizedCitizen }) =>
      lawfulPresenceDocumentation === 'CERTIFICATE_OF_CITIZENSHIP' &&
      isNaturalizedCitizen === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: { id: '43_Q' },
    help: { id: '43_T' },
  },
];
