import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import heic2any from 'heic2any';
import { useDropzone } from 'react-dropzone';
import { Layout } from '@layouts';
import { DocFileType } from '@app/data';
import { UploadProps } from '@hooks/useUpload';
import UploadIcon from '@svg/upload.svg';
import Loading from '../Loading';
import Text from '../Text';
import { generateAcceptedFiles } from './fileTypes';

type DropzoneDisplay = 'avatar';

const StyledContainer = styled.div<{ display?: DropzoneDisplay }>`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  min-height: 200px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgGray};

  ${(p) =>
    p.display === 'avatar' &&
    `
    height: 250px;
    width: 250px;
  `}

  transition: all 300ms ease-in-out;
  &:hover {
    transition: all 300ms ease-in-out;
    opacity: 0.7;
  }
`;

const StyledPreview = styled.img<{ src: string; loading: boolean }>`
  position: absolute;
  height: auto;
  width: 100%;
  opacity: ${(p) => (p.loading ? 0.1 : 1)};
`;

export interface DropzoneProps {
  name: string;
  label: string;
  sublabel?: string;
  disabled?: boolean;
  acceptedTypes?: Array<DocFileType>;
  display?: DropzoneDisplay;
  onUpload?: (props: UploadProps) => void;
  onChange?: (file: File) => void;
  svg?: string; // note: ideally, this would be a specific type
}

// Handles transforming HEIC/HEIF files to JPG
async function transformFile(file) {
  try {
    const blob = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.7,
    });

    // Create a new File object from the converted blob
    const newFile = new File(Array.isArray(blob) ? blob : [blob], `upload-${Date.now()}.jpg`, {
      type: 'image/jpeg',
    });

    return newFile;
  } catch (error) {
    console.error('Error converting HEIC to JPEG:', error);
    throw error;
  }
}

export const Dropzone = ({
  name,
  label,
  sublabel,
  disabled,
  acceptedTypes = Object.values(DocFileType),
  display,
  onUpload,
  onChange,
  svg = UploadIcon,
}: DropzoneProps) => {
  // sets a URL to show the preview
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const accept = useMemo(() => generateAcceptedFiles(acceptedTypes), [acceptedTypes]);

  const onDrop = useCallback(
    async (files: Array<File>) => {
      const initialFile = files[0];

      const type = initialFile.type.toLowerCase();
      const needsTransformation = type.includes('heic') || type.includes('heif');

      const file = needsTransformation ? await transformFile(initialFile) : initialFile;

      setPreview(window.URL.createObjectURL(file));

      // Allow a single file for now
      if (onUpload) {
        onUpload({
          name,
          file,
          options: {
            onStart: () => {
              setLoading(true);
            },
            onError: (e: string) => {
              setLoading(false);
              setError(e);
            },
            onCompleted: (data) => {
              setLoading(false);
            },
          },
        });
      } else {
        onChange?.(files[0]);
      }
    },
    [name],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    disabled: loading || disabled,
  });

  return (
    <StyledContainer {...getRootProps()} display={display} preview={preview}>
      {preview ? (
        <>
          <StyledPreview loading={loading || disabled} src={preview} />
          {loading && <Loading status="loading" titles={{ loading: 'Uploading...' }} />}
        </>
      ) : (
        <>
          <input {...getInputProps()} />
          <Layout.Header margins light align="center" svg={svg} title={label} subtitle={sublabel} />
        </>
      )}
      {error && <Text>{error}</Text>}
    </StyledContainer>
  );
};
