import { useQuery, GET_FEATURE_FLAGS } from '@data';

export const useFlags = () => {
  const { loading, data } = useQuery(GET_FEATURE_FLAGS);

  const flags = data?.me?.flags;

  return {
    loading,
    flags,
    hasAccessToFintech: flags?.hasAccessToFintech || false,
    hasAccessToPhase3: flags?.hasAccessToPhase3 || false,
  };
};
