import React from 'react';

import { Accordion, EmptyState, Loading } from '@uikit';
import { Stack } from '@layouts';
import { date } from '@app/utils/format/date';

import { BankStatementRow, DocumentRow } from './DocumentRow';
import { GroupedDocuments } from './types';

import Doc from '@svg/file.svg';

interface DocumentListProps {
  documentsGroup?: Array<GroupedDocuments>;
  emptyHint?: any;
  loading?: boolean;
}

const DocumentList: React.FC<DocumentListProps> = ({ documentsGroup = [], emptyHint, loading }) => {
  return (
    <Stack separatorComponent spacing="1" topSpace>
      {documentsGroup?.length === 0 ? (
        loading ? (
          <Loading />
        ) : (
          <EmptyState title="Nothing yet" icon={Doc} subtitle={emptyHint} />
        )
      ) : (
        documentsGroup?.map((group, idx) => (
          <Accordion key={group.month} title={date(group.month, 'MONTH_YEAR')} initOpen={idx === 0}>
            <Stack separatorComponent>
              {group.documents.map((doc) => {
                if (doc.__typename === 'BankStatement') {
                  return <BankStatementRow key={doc.id} doc={doc} />;
                }

                if (doc.__typename === 'TaxForm' || doc.__typename === 'AccountStatement') {
                  return <DocumentRow key={doc.id} doc={doc} />;
                }

                return null;
              })}
            </Stack>
          </Accordion>
        ))
      )}
    </Stack>
  );
};

export default DocumentList;
