import {
  format,
  isValid,
  parse,
  subDays,
  isWithinInterval,
  addDays,
  isBefore,
  isFuture,
  differenceInCalendarMonths,
  isDate,
  addMonths,
  addYears,
  lastDayOfMonth,
  getDay,
  startOfMonth,
} from 'date-fns';

const daysAgo = (days, format = 'MMMM d, yyyy') => {
  return safeFormatDate(subDays(new Date(), days), format);
};

const daysAhead = (days, format = 'MMMM d, yyyy') => {
  return safeFormatDate(addDays(new Date(), days), format);
};

const monthsAhead = (months, format = 'MMMM d, yyyy') => {
  return safeFormatDate(addMonths(new Date(), months), format);
};

/**
 * @todo switch functions like this to libraries already in use
 */
export const _isWithinDays = (days) => (date) => {
  return isWithinInterval(date, {
    start: new Date(),
    end: addDays(new Date(), days),
  });
};

export const sixtyDaysAgo = () => daysAgo(60);
export const ninetyDaysAgo = () => daysAgo(90);
export const fiveYearsAgo = () => daysAgo(1826); // 365 * 5 + 1 leap year day
export const sixtyDaysFromNow = () => daysAhead(60);
export const firstDayOfSomeMonth = (n) => startOfMonth(addMonths(new Date(), n));
export const firstDayOfNextMonth = () => firstDayOfSomeMonth(1);

/**
 * Returns the date of the last tuesday in july
 * this is helper for EDE question #236
 */
export const lastTuesdayOfJuly = () => {
  // gets last day of july (note: july is month 6 because it's indexed from 0)
  const lastDayOfJuly = lastDayOfMonth(new Date(currentYear, 6, 1));

  // calculate how many days to subtract to get the last Tuesday
  const daysToSubtract = (getDay(lastDayOfJuly) + 5) % 7;

  // subtract days to get the last Tuesday of July
  return subDays(lastDayOfJuly, daysToSubtract);
};

// ISO formatting
export const todayISO = () => safeFormatDate(new Date(), 'yyyy-MM-dd');
export const sixtyDaysAgoISO = () => daysAgo(60, 'yyyy-MM-dd');
export const ninetyDaysAgoISO = () => daysAgo(90, 'yyyy-MM-dd');
export const sixtyDaysFromNowISO = () => daysAhead(60, 'yyyy-MM-dd');
export const threeMonthsFromNowISO = () => monthsAhead(3, 'yyyy-MM-dd');

export const isWithin30Days = _isWithinDays(30);

export const isWithinLast60Days = (date) => {
  const d = parse(date, 'MM/dd/yyyy', new Date());
  return isWithinInterval(d, {
    start: subDays(new Date(), 60),
    end: new Date(),
  });
};

export const isWithinNext60Days = (date) => {
  const d = parse(date, 'MM/dd/yyyy', new Date());
  const check = _isWithinDays(60);
  return check(d);
};

/**
 * Need some special handling at the end of the current year
 * for SEP and CiC actions
 *
 * We think this will be sufficiently aware of TZ issues because it
 * runs in the browser
 */
export const isWithinLastTwoWeeksOfYear = () => {
  const d = new Date(Date.now());
  const out = isWithinInterval(d, {
    start: new Date(currentYear, 11, 16),
    end: new Date(currentYear, 11, 31),
  });

  return out;
};

/**
 * Given the start and end of open enrollment,
 * determines if it is currently OE for the user
 * Note: both startTime and endTime are both UTC datetimes
 */
export const withinInterval = ({ start, end }, referenceDate = '') => {
  const comparison = referenceDate || new Date();

  if (!start || !end) return false;

  // if end date is before start date, it throws
  // in that case, just return false
  try {
    return isWithinInterval(comparison, {
      start: new Date(start),
      end: new Date(end),
    });
  } catch (e) {
    return false;
  }
};

/**
 * Given possible date
 * - If it matches YYYY-MM-DD format, convert to a date from ISO
 * - Otherwise, just return the passed date
 */
const standardizeISO = (date = '') => {
  if (!/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(date)) {
    return date;
  }
  const year = date?.substring(0, 4);
  const month = date?.substring(5, 7);
  const day = date?.substring(8, 10);
  return new Date(year, month - 1, day);
};

/**
 * Formats a date
 * - first check if defined and valid date
 * - then format using the pattern string
 * @todo migrate to "new" utils aka date.ts
 */
export const safeFormatDate = (initialDate, pattern = 'MMMM dd, yyyy') => {
  const date = standardizeISO(initialDate);
  if (!date || !isValid(date)) return '';
  return format(date, pattern);
};

export function toGQLDate(date) {
  if (typeof date !== 'string') return date;
  const [month, day, year] = date.split('/');
  return safeFormatDate(new Date(year, month - 1, day), 'yyyy-MM-dd');
}

export const convertUnixTimeToAge = (datetime) => {
  return Math.floor((new Date().getTime() - new Date(datetime)) / (1000 * 60 * 60 * 24 * 365));
};

export const getToday = () => new Date(Date.now());
const today = getToday();

export const getYesterday = () => {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  return d;
};

/**
 * Calculates last year, this year, and next year
 */
export const getYear = (date) => date.getFullYear();
export const currentYear = today.getFullYear();
export const lastYear = today.getFullYear() - 1;
export const nextYear = today.getFullYear() + 1;

/**
 * Given a referenceDate and a list of ordered dates,
 * returns the index of the next date after the reference date
 * if there is no next date, return -1
 *
 * Figures out where the reference date falls.
 *
 * For example: assume we have two dates Jan 5, and Feb 5
 * If we were comparing Jan 1, the index of the next date is 0 (Jan 5 is next date)
 * If we were comparing Jan 20, the index of the next date is 1 (Feb 5 is next date).
 * If we were comparing Feb 20, we'd return -1 since there is no "next" date
 */
export const getIndexOfNextDate = (referenceDate, dates) => {
  const lastIndex = dates.length - 1;

  const index = dates
    .concat(referenceDate)
    .sort((a, b) => a - b)
    .indexOf(referenceDate);

  return index > lastIndex ? -1 : index;
};

const second = 1000;
const minute = second * 60;

export function mins(amount = 1) {
  return minute * amount;
}

export function bornBefore(dob, referenceDate) {
  return isBefore(new Date(dob), new Date(referenceDate));
}

/**
 * Users must be 18 years old at the beginning of the calendar year in order to use Catch. This is for tax/investment reasons
 * Not worth time to figure out how to treat people who may not owe taxes at all.
 */
export function twelveYearsAgo() {
  let curYear = new Date();
  let d = curYear.setFullYear(curYear.getFullYear() - 12);
  return d;
}
export function eighteenYearsAgo() {
  let curYear = new Date();
  let d = curYear.setFullYear(curYear.getFullYear() - 18);
  return d;
}

export function sixtyFiveYearsAgo() {
  let curYear = new Date();
  let d = curYear.setFullYear(curYear.getFullYear() - 65);
  return d;
}

export function oneHundredTenYearsAgo() {
  let curYear = new Date().getFullYear();
  let firstDay = new Date(curYear, 1, 1);
  let d = firstDay.setFullYear(firstDay.getFullYear() - 110);
  return d;
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export {
  format,
  isValid,
  parse,
  subDays,
  isWithinInterval,
  addDays,
  isBefore,
  isFuture,
  differenceInCalendarMonths,
  isDate,
  addMonths,
  addYears,
  monthNames,
};
