// @ts-ignore
import React, { useMemo, useState } from 'react';
import { Segment, SegmentEvent, handleHealthErrors, useCopy } from '@app/utils';
import {
  useDeprecatedMutation,
  useQuery,
  HealthApplicationQuery,
  getMembers,
  useMutation,
  mutations,
} from '@data';
import { navigate, sheets, useSheet } from '@navigate';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { fields, followUpFields } from './immigrationDetailsFields';
import { formatPayload, getInitialValues } from './immigrationDetailsUtils';
import { shallow } from 'zustand/shallow';
import { loaders } from '@app/config';
import { ToastType, popToast } from '@app/_ui-kit';
import { Route } from '@app/types';

const ImmigrationDetails = ({ isGA, applicationID }) => {
  const [followUp, setFollowUp] = useState(false);
  const [asked75, setAsked75] = useState(false);

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
    // attempt to address issue similar to https://linear.app/catch/issue/ESC-27/incarcerationpregnancy-qs-dont-show-hh-members
    fetchPolicy: 'cache-and-network',
  });

  const { members, isRequestingFinancialAssistance, self, spouse, dacaIneligible, coverageState } =
    useMemo(() => {
      const { screening, uiQuestionsToDisplay, coverageState } =
        data?.viewerTwo.health.application || {};

      const allMembers = getMembers(data);

      const self = allMembers.find((m) => m?.relation === 'SELF');
      const spouse = allMembers.find((m) => m?.relation === 'SPOUSE');

      // if we've already asked question 75 on the initial load of this page,
      // then we don't need to ensure and follow up
      if (!followUp && uiQuestionsToDisplay?.['75']) {
        setAsked75(true);
      }

      const dacaIneligibleStates = [
        'AL',
        'AR',
        'FL',
        'IN',
        'IA',
        'KS',
        'MO',
        'MT',
        'NE',
        'NH',
        'ND',
        'OH',
        'SC',
        'SD',
        'TN',
        'TX',
      ];

      return {
        members: allMembers.filter((m) =>
          followUp
            ? m?.uiQuestionsToDisplay?.['75']
            : m?.uiQuestionsToDisplay?.['45'] || m?.uiQuestionsToDisplay?.['40'],
        ),
        isRequestingFinancialAssistance: screening?.isRequestingFinancialAssistance,
        self,
        spouse,
        coverageState,
        dacaIneligible: coverageState && dacaIneligibleStates.includes(coverageState),
      };
    }, [data?.viewerTwo.health.application, followUp]);

  const handleNext = () => {
    Segment.track(SegmentEvent.APPLICATION_IMMIGRATION_COMPLETED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    if (isRequestingFinancialAssistance) {
      navigate(Route.EDE_MEDICAID_DENIAL);
    } else {
      navigate(Route.EDE_SEP);
    }
  };

  const { c } = useCopy(`catch.ede.ImmigrationDetails`);

  const { openLoader, openSheet, close } = useSheet(
    (state) => ({
      openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT),
      openSheet: state.open,
      close: state.closeAll,
    }),
    shallow,
  );

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  // @ts-ignore
  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    ...handleHealthErrors,
    onCompleted: (data) => {
      // check for whether we need to show question 75 as follow up afterwards
      if (data?.uiQuestionsToDisplay?.['75']) {
        setFollowUp(true);
        close();
        popToast({
          type: ToastType.coverage,
          msg: "We've added a question based on your answers",
          title: "We've added another question",
          autoCloseIn: 5000,
        });
      } else {
        handleNext();
      }
    },
  });

  const shouldWaitForImmigrationWarning = (values) => {
    // gets a list of fields that are currently displayed
    const displayedFields = fields?.filter((field) => {
      if (typeof field.display === 'function') return field.display(values);
      if (typeof field.display === 'boolean') return field.display;
      return true;
    });

    // we should show the modal if any of the displayed fields haven't been answered
    return displayedFields.some((field) => {
      const val = values[field.name];
      return val === null || val === undefined || val === '';
    });
  };

  const showMissingImmigrationFields = (values, { handleNext }) => {
    openSheet(sheets.MISSING_IMMIGRATION_FIELDS, { onContinue: handleNext });
  };

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      submitting={ensuring}
      title={(values) => (values?.givenName ? c('nameTitle', { name: values?.givenName }) : '')}
      data={{ isGA, self, spouse, isRequestingFinancialAssistance, coverageState, dacaIneligible }}
      members={members}
      fields={followUp ? followUpFields : fields}
      getInitialValues={getInitialValues}
      shouldWait={shouldWaitForImmigrationWarning}
      onWait={showMissingImmigrationFields}
      onNext={(values) => upsert(formatPayload({ applicationID, values, followUp }))}
      onComplete={async ({ reset }) => {
        if (!followUp && !asked75) {
          openLoader();
          await ensure({ variables: { applicationID } });
          reset();
        } else {
          handleNext();
        }
      }}
    />
  );
};

export const ImmigrationDetailsView = {
  name: 'EDE_IMMIGRATION_DETAILS',
  component: ImmigrationDetails,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Immigration details',
  },
};
