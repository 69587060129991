import { lowercase } from '@app/utils';
import ConfirmApplicantsFooter from './ConfirmApplicantsFooter';
import ConfirmApplicantsMenu from './ConfirmApplicantsMenu';

export const fields = [
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'members',
    type: 'array',
    grouped: true,
    Component: ({ children }) => children,
    Footer: ConfirmApplicantsFooter,
    dependencies: [
      'members',
      'SUBFIELD:id',
      'SUBFIELD:applicationID',
      'SUBFIELD:relation',
      'SUBFIELD:fullName',
    ],
    help: { id: '273' },
    subfields: [
      {
        name: 'isRequestingCoverage',
        bare: true,
        type: 'person',
        dependencies: ['fullName', 'relation'],
        label: ({ fullName, relation }) => fullName || `Your ${lowercase(relation)}`,
        help: { id: '273' },
        secondary: {
          unchecked: 'Not applying',
          checked: 'Applying',
        },
        Accessory: ConfirmApplicantsMenu,
      },
    ],
  },
];

export const removeApplicantFields = [
  {
    copy: 'catch.ede',
    testID: '274',
    name: 'removeReason',
    required: true,
    type: 'option',
    dependencies: ['relation', 'removeReason', 'fullName'],
    label: ({ relation }) => ({ id: 'HealthEnrollment.274_Q', data: { relation } }),
    options: [
      { label: { id: 'CIC.selection.DEATH' }, value: 'DEATH' },
      { label: { id: 'CIC.selection.DIVORCE' }, value: 'DIVORCE' },
      { label: { id: 'CIC.selection.OTHER' }, value: 'OTHER' },
    ],
    fieldAddition: ({ fullName, removeReason }) => {
      if (removeReason === 'OTHER') {
        return {
          component: 'BANNER',
          props: {
            title: `If ${fullName} wants to end their Marketplace coverage because they're starting Medicare, do not remove them from the application. You will be asked about Medicare enrollment later in the application.`,
            bg: 'mediumLight',
          },
        };
      }
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '274_a',
    name: 'removeDate',
    required: true,
    type: 'date',
    format: 'YYYY-MM-DD',
    dependencies: ['removeReason'],
    label: ({ removeReason }) => ({ id: '274_a_L', data: { removeReason } }),
    display: ({ removeReason }) => /DEATH|DIVORCE/.test(removeReason),
  },
];
