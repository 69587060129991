import { useEffect, useState } from 'react';
import { useQuery, queries, DocumentQueryData, useDeprecatedMutation } from '@data';
import { useSkip } from './useSkip';

/**
 * useDocument hook for handling downloads of wealth and bank statements
 * statetementType refers to either SAVINGS or WEALTH
 * given the statement id and the type of statement to download
 * returns a function to download
 */

export const useDocument = ({ id, type = '', providerId = '' }) => {
  const { fetch, skip } = useSkip();
  const [mutationURL, setURL] = useState();
  const skipDocumentQuery = type === 'TAX_1095_A';

  const { data } = useQuery<DocumentQueryData>(queries.DOCUMENT, {
    variables: { id },
    skip: skip || skipDocumentQuery,
  });

  let queryURL = data?.viewerTwo?.document?.url;

  const [getURL] = useDeprecatedMutation('getGeneratedDocumentURL', {
    variables: {
      input: {
        dsrsIdentifier: providerId,
      },
    },
  });

  useEffect(() => {
    if (!skip && !mutationURL && skipDocumentQuery) {
      getURL().then(({ data: { getGeneratedDocumentURL } }) => {
        setURL(getGeneratedDocumentURL);
      });
    }
  }, [skip]);

  const url = queryURL || mutationURL;
  // Trigger for actually downloading the statement
  const download = () => window.open(url);
  // When statement URL is set for first time, download
  useEffect(() => {
    if (!!url) download();
  }, [url]);

  // Either fetch the URL or download the existing url
  return { downloadDocument: url ? download : fetch };
};
